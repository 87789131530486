import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import {Helmet} from 'react-helmet';
import Page from '../components/Page';
import {graphql} from 'gatsby';

export default function Index({data: {pageSeo, missionStatement, allQxpCmsFeature, allHomeSlideShow}}) {
	return <Page>

		<Helmet>
			<meta charSet="utf-8" />
			<title>{pageSeo.PageTitle}</title>
			<meta name="description" content={pageSeo.Description} />

			<link rel="canonical" href="https://www.qxp.com.au/" />
		</Helmet>

		<Container fluid>
			<Row>
				<Col>
					<h1>{missionStatement.Title}</h1>
					<h3>{missionStatement.BodyText}</h3>
				</Col>

			</Row>
			<Row>
				<Col md={6}>
					<h3>Features</h3>
					{allQxpCmsFeature.nodes.map(feature => {
						return <div key={feature.Title}>
							<h4>{feature.Title}</h4>
							<p>{feature.BodyText}</p>
						</div>;
					})}
				</Col>
				<Col md={6}>
					<Carousel>
						{allHomeSlideShow.nodes.filter((slide,index) => index === 0).map((slide, index) => {
							return (
								<Carousel.Item key={index}>
									<img
										className="d-block mw-100 mh-100"
										src={slide.Image.URL}
										alt={slide.Title}
										title={slide.Title}
									/>
								</Carousel.Item>
							);
						})}
					</Carousel>
				</Col>
			</Row>
		</Container>
	</Page>;
}

export const query = graphql`
query {
	missionStatement {
		Title
		BodyText
	}
	allHomeSlideShow {
		nodes {
			Image {
				URL
				hash
				name
			}
			Title
			AltTag
		}
	}
	allQxpCmsFeature {
		nodes {
		Title
		BodyText
		}
	}

    pageSeo(id: {eq: "60825d43-182d-e1e7-a603-5650c5d79ec4"}) {
	  PageTitle
	  Description
	}
}
`;
